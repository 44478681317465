<script>
  import { Line } from 'vue-chartjs';
  import { hexToRgb } from '@/utils/color';
  import {
    getJamFactorColor,
    JAM_FACTOR_TYPE_FLUID,
    JAM_FACTOR_TYPE_JAMMED,
    JAM_FACTOR_TYPE_SLOW,
    JAM_FACTOR_TYPE_STOPPED
  } from '@/utils/section';
  import chartjsPluginAnnotation from 'chartjs-plugin-annotation';

  export default {
    extends: Line,

    props: {
      history: {
        type: Array,
        required: true,
      },
      offset: {
        type: Number,
        default: null,
      },
      maxSpeed: {
        type: Number,
        required: true,
      },
    },

    data () {
      return {
        types: [],
        options: {
          onResize: () => {
            this.$nextTick(() => {
              this.render({
                animation: {
                  duration: false,
                },
              });
            });
          },
          responsive: true,
          maintainAspectRatio: false,
          legend: {
            display: false,
          },
          tooltips: {
            enabled: false,
          },
          elements: {
            point: {
              radius: 0,
            },
          },
          scales: {
            xAxes: [{
              display: false,
            }],
            yAxes: [{
              display: false,
              ticks: {
                min: 0,
                max: 50,
                stepSize: 10,
              },
            }],
          },
        },
      };
    },

    computed: {
      chartData () {
        const fluid = hexToRgb(getJamFactorColor(JAM_FACTOR_TYPE_FLUID));
        const slow = hexToRgb(getJamFactorColor(JAM_FACTOR_TYPE_SLOW));
        const jammed = hexToRgb(getJamFactorColor(JAM_FACTOR_TYPE_JAMMED));
        const stopped = hexToRgb(getJamFactorColor(JAM_FACTOR_TYPE_STOPPED));
        const opacity = 0.5;

        const max = this.getMaxChartValue();
        const freeFlowSpeedPercentage = 1 - this.maxSpeed / max;
        const fromY = 8;
        const toY = this.height;
        const chartHeight = toY - fromY;
        const freeFlowSpeedBreakpoint = Math.round(chartHeight * freeFlowSpeedPercentage);

        var ctx = document.getElementById('line-chart').getContext('2d');

        var gradientStroke = ctx.createLinearGradient(0, fromY + freeFlowSpeedBreakpoint, 0, toY);
        gradientStroke.addColorStop(0, 'rgb(' + fluid.r + ',' + fluid.g + ',' + fluid.b + ',' + opacity + ')');
        gradientStroke.addColorStop(0.4, 'rgb(' + slow.r + ',' + slow.g + ',' + slow.b + ',' + opacity + ')');
        gradientStroke.addColorStop(0.8, 'rgb(' + jammed.r + ',' + jammed.g + ',' + jammed.b + ',' + opacity + ')');
        gradientStroke.addColorStop(1, 'rgb(' + stopped.r + ',' + stopped.g + ',' + stopped.b + ',' + opacity + ')');

        return {
          labels: this.history.map((item, index) => index),
          datasets: [{
            data: this.history.map(item => parseInt(item)),
            backgroundColor: gradientStroke,
            borderWidth: 0,
          }],
        };
      },
    },

    watch: {
      history () {
        this.render({
          animation: {
            duration: false,
          },
        });
      },
      offset () {
        this.render({
          animation: {
            duration: false,
          },
        });
      },
    },

    mounted () {
      this.addPlugin([chartjsPluginAnnotation]);
      this.render();
    },

    methods: {
      render (options = {}) {
        if (this.offset !== null) {
          // Encode then Decode from JSON to solve annotation plugin issue
          // https://github.com/chartjs/chartjs-plugin-annotation/issues/188#issuecomment-730083242
          options = JSON.parse(JSON.stringify(options));

          options.annotation = {
            annotations: [{
              type: 'line',
              mode: 'vertical',
              scaleID: 'x-axis-0',
              value: this.offset.toString(),
              borderDash: [5, 2],
              borderColor: this.$vuetify.theme.themes.light.primary,
              borderWidth: 1,
            }],
          };
        }

        this.renderChart(this.chartData, {
          ...this.options,
          ...options,
        });
      },
      getMaxValue () {
        return Math.max.apply(Math, this.history);
      },
      getMaxChartValue () {
        let max = this.getMaxValue();
        const rest = max % 10;
        max += 10 - rest;
        if (rest > 5) {
          max += 10;
        }

        return Math.ceil(max);
      },
    },
  };
</script>
